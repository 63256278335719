<template>
  <button
    class="btn"
    @click="navigateToUrl"
  >
    {{ text }}
  </button>
</template>

<script setup lang="ts">
const { text, bgColor, url } = defineProps({
  text: {
    type: String,
    default: 'ボタン',
  },
  bgColor: {
    type: String,
    default: 'primary',
  },
  url: {
    type: String,
    default: '',
  },
})

const calcColor = computed(() => {
  return `var(--${bgColor})`
})

const navigateToUrl = () => {
  if (url) {
    window.location.href = url
  }
  // TODO: urlではない場合は、functionが動くようにする
}
</script>

<style lang="scss" scoped>
  .btn {
  width: 100%;
  text-align: center;
  min-width: fit-content;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: $body-s;
  letter-spacing: calc($body-s / 10);
  border-radius: 0.225rem;
  background-color: v-bind(calcColor);

  &:hover {
    opacity: 0.6;
    color: var(--white);
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
}
</style>
